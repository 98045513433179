import { initReactI18next } from 'react-i18next';

import i18n, { Resource } from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

import { storageKeys } from '@/consts/storageKeys.ts';
import { ILocaleEnumKeys } from '@/types/ILocaleEnum.ts';
import { kzTranslations } from '@/utils/configs/locales/kz.ts';
import { ruTranslations } from '@/utils/configs/locales/ru.ts';

const resources: Record<ILocaleEnumKeys, Resource> = {
  kk: kzTranslations,
  ru: ruTranslations,
};

let storageLanguage: ILocaleEnumKeys =
  (localStorage.getItem(storageKeys.locale) as ILocaleEnumKeys) || 'ru';

if (!Object.keys(resources).includes(storageLanguage)) {
  storageLanguage = 'ru';
}

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    debug: false,
    defaultNS: 'translation',
    detection: {
      lookupLocalStorage: storageKeys.locale,
    },
    fallbackLng: 'ru',
    interpolation: { escapeValue: false },
    keySeparator: '.',
    lng: storageLanguage,
    ns: ['translation'],
    react: { useSuspense: false },
    resources,
    returnNull: true,
  });

export default i18n;
